<template>
  <div class="row">
    <div class="col-md-12">
      <label class="sub-title pb-2"
        >Base Node<span class="base">●</span>
      </label>
    </div>
    <div class="col-md-12">
      <div class="form-check form-check-inline">
        <input
          type="radio"
          name="baseNodeRadioOptions"
          class="form-check-input"
          v-model="baseNodeOption"
          value="all"
          id="findAll"
          @click="inputDisable('all')"
        /><label class="form-check-label" for="findAll">Find All</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          type="radio"
          name="baseNodeRadioOptions"
          class="form-check-input"
          v-model="baseNodeOption"
          value="nearest"
          id="baseNodeOption"
          @click="inputDisable('nearest')"
        /><label class="form-check-label" for="baseNodeOption"
          >Find Nearest</label
        >
      </div>
      <div class="form-check form-check-inline">
        <input
          type="radio"
          name="baseNodeRadioOptions"
          class="form-check-input"
          v-model="baseNodeOption"
          value="use_specific"
          id="useSpecific"
          @click="inputDisable('use_specific')"
        />
        <label class="form-check-label" for="useSpecific">Use Specific</label>
      </div>
    </div>
    <div class="col-md-12" v-if="baseNodeOption != 'all'">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="serial_number">Serial Number</label>
            <input
              type="text"
              name="serial_number"
              class="form-control"
              v-model="serial"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="region">Region</label>
            <input
              type="text"
              name="region"
              class="form-control"
              v-model="region"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="location">Location</label>
            <input
              type="text"
              name="location"
              class="form-control"
              v-model="bn_location"
              :class="{
                'border-danger': !checkCoordinates(bn_location),
              }"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="market_site">Market / Site</label>
            <input
              type="text"
              name="market_site"
              class="form-control"
              v-model="market_site"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="azimuth_tilt">Azimuth / Tilt (&deg;)</label>
            <input
              type="text"
              name="azimuth_tilt"
              class="form-control"
              v-model="azimuth_tilt"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="cell">Cell</label>
            <input
              type="text"
              name="cell"
              class="form-control"
              v-model="cell"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="height">Height AGL (m)</label>
            <input
              type="text"
              name="height"
              class="form-control"
              v-model="height_agl"
              :class="{ 'border-danger': !checkNumber(height_agl) }"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="sector">Sector</label>
            <input
              type="text"
              name="sector"
              class="form-control"
              v-model="sector"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="height">Height MSL (m)</label>
            <input
              type="text"
              name="height"
              class="form-control"
              v-model="height_gps"
              :class="{ 'border-danger': !checkNumber(height_gps) }"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="carrier">Carriers (MHz)</label>
            <input
              type="text"
              name="carrier"
              class="form-control"
              v-model="carrier"
              :disabled="disabled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    operatorKey: String,
    operator: String,
  },
  data() {
    return {
      serial: "",
      cell: "",
      market: "",
      site: "",
      sector: "",
      firmware: "",
      region: "",
      bn_location: "",
      azimuth: "",
      height_agl: "",
      height_gps: "",
      ground_msl: "",
      downtilt: "",
      azimuth_tilt: "",
      market_site: "",
      carrier: "",
      baseNodeOption: "all",
      submitted: false,
      disabled: true,
    };
  },
  computed: {
    useSpecific() {
      return this.baseNodeOption == "use_specific";
    },
    findAll() {
      return this.baseNodeOption == "all";
    },
  },
  methods: {
    inputDisable(val) {
      this.disabled = false;
      if (val == "nearest") {
        this.disabled = true;
      }
      this.submitted = false;
      if (val == "all") {
        this.clearInputs();
      }
    },
    clearInputs() {
      this.serial = "";
      this.cell = "";
      this.market = "";
      this.site = "";
      this.sector = "";
      this.firmware = "";
      this.region = "";
      this.bn_location = "";
      this.azimuth = "";
      this.height_agl = "";
      this.height_gps = "";
      this.downtilt = "";
      this.ground_msl = "";
      this.azimuth_tilt = "";
      this.market_site = "";
      this.carrier = "";

      if (this.baseNodeOption != "all") {
        this.baseNodeOption = "all";
        this.inputDisable("all");
      }
    },
    checkNumber(value) {
      return this.$utils.isValidNumber(value);
    },
    checkCoordinates(value, type = "") {
      return this.$utils.isValidCoordinates(value, type);
    },
    isFormValid() {
      this.submitted = true;
      if (this.baseNodeOption != "all") {
        return (
          this.checkNumber(this.height_agl) &&
          this.checkNumber(this.height_gps) &&
          this.checkCoordinates(this.bn_location)
        );
      }
      return true;
    },
    getJsonData() {
      let bnObject = {};
      if (!this.disabled && this.baseNodeOption != "all") {
        bnObject.operator = this.operator;
        if (this.serial != "") bnObject.serial = this.serial;
        if (this.cell != "") bnObject.cell = this.cell;
        if (this.sector != "") bnObject.sector = this.sector;
        if (this.region != "") bnObject.region = this.region;
        if (this.firmware != "") bnObject.firmware = this.firmware;
        if (this.ground_msl != "") bnObject.ground_msl = this.ground_msl;
        if (this.bn_location != "") {
          let bnLocationArray = this.bn_location.split(", ");
          bnObject.latitude = parseFloat(bnLocationArray[0]);
          bnObject.longitude = parseFloat(bnLocationArray[1]);
        }
        if (this.market_site != "") {
          let marketSiteArray = this.market_site.split("/");
          this.market = marketSiteArray[0] ?? "";
          this.site = marketSiteArray[1] ?? "";
          if (this.market != "") bnObject.market = this.market.trim();
          if (this.site != "") bnObject.site = this.site.trim();
        }
        if (this.height_agl != "") bnObject.height_agl = this.height_agl;
        if (this.height_gps != "") bnObject.height_gps = this.height_gps;
        if (this.azimuth_tilt != "") {
          let azimuthTiltArray = this.azimuth_tilt.split("/");
          this.azimuth = azimuthTiltArray[0]
            ? parseFloat(azimuthTiltArray[0])
            : "0";
          this.downtilt = azimuthTiltArray[1]
            ? parseFloat(azimuthTiltArray[1])
            : "0";
          if (this.azimuth != "") bnObject.azimuth = this.azimuth;
          if (this.downtilt != "") bnObject.downtilt = this.downtilt;
        }
        //if (this.carrier.length > 0) bnObject.carrier = this.carrier;
      }
      return bnObject;
    },
    updateFields(data) {
      this.baseNodeOption = "use_specific";
      this.inputDisable("use_specific");
      this.serial = data.serial;
      this.cell = data.cell;
      this.market = data.market;
      this.site = data.site;
      this.sector = data.sector;
      this.firmware = data.firmware;
      this.region = data.region;
      this.bn_latitude = data.latitude ? parseFloat(data.latitude) : "";
      this.bn_longitude = data.longitude ? parseFloat(data.longitude) : "";
      if (this.bn_latitude != "" && this.bn_longitude != "") {
        this.bn_location = this.bn_latitude + ", " + this.bn_longitude;
      }

      this.height_agl = data.height_agl ? parseFloat(data.height_agl) : "";
      this.height_gps = data.height_gps ? parseFloat(data.height_gps) : "";
      this.azimuth = data.azimuth ? parseFloat(data.azimuth) : "0";
      this.downtilt = data.downtilt ? parseFloat(data.downtilt) : "0";
      this.azimuth_tilt = this.azimuth + " / " + this.downtilt;
      this.market_site = this.market + " / " + this.site;
      this.ground_msl = data.ground_msl ? parseFloat(data.ground_msl) : "";
      this.carrier =
        data.carrier.length > 0
          ? this.formatCarrierValues("frequency", data.carrier)
          : "";
    },
    formatCarrierValues(key, data) {
      if (data.length > 0) {
        let carrier = data
          .filter((item) => item[key] !== 0) // Exclude entries with zero values
          .map((item) => item[key])
          .join(", ");
        return carrier;
      }
      return "";
    },
  },
};
</script>
