export function createLabelOverlay() {
  if (!window.google || !window.google.maps) {
    throw new Error("Google Maps API is not loaded yet.");
  }

  let lastClickedLabel = null; // Track the last clicked label

  return class LabelOverlay extends google.maps.OverlayView {
    constructor(position, text, map, onClick, styles) {
      super();
      this.position = position;
      this.text = text;
      this.map = map;
      this.onClick = onClick;
      this.styles = styles;
      this.div = null;
      this.setMap(map);
    }

    onAdd() {
      this.div = document.createElement("div");
      this.div.className = "map-label";
      this.div.innerHTML = this.text;

      // **Fix: Ensure Click Event Works & Prevents Cluster Interference**
      this.div.style.backgroundColor = "#15202b";
      this.div.style.position = "absolute";
      this.div.style.cursor = "pointer";
      this.div.style.zIndex = `${Math.floor(Date.now() / 1000)}`; // Ensure it's above markers
      this.div.style.pointerEvents = "auto"; // Ensures it can be clicked

      this.div.addEventListener("click", (event) => {
        event.stopPropagation(); // Prevents event bubbling to map/markers
        this.handleLabelClick();
        if (this.onClick) this.onClick(event);
      });

      const panes = this.getPanes();
      panes.overlayMouseTarget.appendChild(this.div);
    }

    handleLabelClick() {
      // Reset the previous clicked label's color
      if (lastClickedLabel && lastClickedLabel !== this) {
        lastClickedLabel.resetStyle();
      }

      // Apply new style to the clicked label
      this.applyStyles(); // Change to highlight color
    }

    applyStyles() {
      if (!this.div) return; // Ensure div exists

      if (this.styles.backgroundColor) {
        this.div.style.backgroundColor = this.styles.backgroundColor;
      }

      lastClickedLabel = this; // Update last clicked label
    }

    setStyle(styles) {
      this.styles = styles;

      if (this.div) {
        this.applyStyles();
      } else {
        // Ensure styles apply once the div is available
        requestAnimationFrame(() => {
          if (this.div) {
            this.applyStyles();
          }
        });
      }
    }

    resetStyle() {
      if (this.div) {
        this.div.style.backgroundColor = "#15202b";
      }
    }

    draw() {
      if (!this.div) return;
      const overlayProjection = this.getProjection();
      const position = overlayProjection.fromLatLngToDivPixel(this.position);

      if (position) {
        this.div.style.transform = `translate(${position.x}px, ${position.y}px) translate(-50%, -50%)`; // Center the label
      }
    }

    onRemove() {
      if (this.div) {
        this.div.remove();
        this.div = null;
      }
    }
  };
}
