<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label class="sub-title pe-1 pb-2"
          >Remote Node<span class="remote">●</span>
        </label>
        <div class="form-check form-check-inline">
          <input
            type="radio"
            name="remoteNodeRadioOptions"
            class="form-check-input"
            v-model="findByAddress"
            value="true"
            id="findByAddress"
            @click="remoteChange(true)"
          /><label class="form-check-label" for="findByAddress">Address</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            type="radio"
            name="remoteNodeRadioOptions"
            class="form-check-input"
            v-model="findByAddress"
            value="false"
            id="findBylatlong"
            @click="remoteChange(false)"
          />
          <label class="form-check-label" for="findBylatlong">Lat / Long</label>
        </div>
        <!--<button
          type="button"
          class="btn btn-secondary remote-clear-btn"
          @click="clearInputs('rn')"
        >
          Clear
        </button>-->
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label for="subscriber">Subscriber ID / Notes</label>
        <input
          type="text"
          name="subscriber"
          class="form-control"
          v-model="rn_subscriber"
        />
      </div>
    </div>
    <div class="col-md-12" v-if="rnAddressShow">
      <div class="form-group input-group">
        <label for="address">Address</label>
        <input
          type="text"
          name="address"
          class="form-control"
          v-model="rn_address"
          :class="addressError ? 'border-danger' : ''"
          @change="getOperators"
        />
        <span class="input-group-text cursor-pointer" @click="getOperators"
          ><i class="fa fa-search" aria-hidden="true"></i>
        </span>
      </div>
      <div v-if="addressErrorMsg" class="text-danger">
        {{ addressErrorMsg }}
      </div>
    </div>
    <div class="col-md-12" v-else>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="address">Latitude</label>
            <input
              type="text"
              name="latitude"
              class="form-control"
              v-model="rn_latitude"
              :class="{
                'border-danger': !checkCoordinates(rn_latitude, 'latitude'),
              }"
              @change="findAddress(true)"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="longitude">Longitude</label>
            <input
              type="text"
              name="location"
              class="form-control"
              v-model="rn_longitude"
              :class="{
                'border-danger': !checkCoordinates(rn_longitude, 'longitude'),
              }"
              @change="findAddress(true)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rn_latitude: "",
      rn_longitude: "",
      rn_address: "",
      rn_location: {},
      rn_above_open: "",
      rn_above_roof: "",
      rn_subscriber: "",
      findByAddress: true,
      rnAddressShow: true,
      latlngUpdated: false,
      addressUpdated: false,
      addressError: false,
      submitted: false,
      addressErrorMsg: "",
      apiResponse: "",
      addresErrorMsg: "",
    };
  },
  computed: {
    findBylatlong() {
      return !this.findByAddress;
    },
  },
  methods: {
    clearInputs(clearMapRN = "") {
      this.rn_latitude = "";
      this.rn_longitude = "";
      this.rn_address = "";
      this.rn_location = {};
      this.rn_above_open = "";
      this.rn_above_roof = "";
      this.rn_subscriber = "";
      this.findByAddress = true;
      this.remoteChange(true);
      if (clearMapRN) {
        this.$emit("clear-rn-marker");
      }
    },
    remoteChange(val) {
      this.rnAddressShow = val;
      this.addressError = false;
    },
    checkNumber(value) {
      return this.$utils.isValidNumber(value);
    },
    checkCoordinates(value, type = "") {
      if (value == "" && !this.rnAddressShow && this.submitted) {
        return false;
      }
      return this.$utils.isValidCoordinates(value, type);
    },
    isFormValid() {
      this.submitted = true;
      if (this.rnAddressShow) {
        this.submitted = this.rn_address != "" ? true : false;
        this.addressError = !this.submitted;
      }
      return (
        this.checkCoordinates(this.rn_latitude, "latitude") &&
        this.checkCoordinates(this.rn_longitude, "longitude") &&
        this.submitted
      );
    },
    updateFields(data) {
      if (data.address) {
        this.rn_address = data?.address;
        this.rn_location = {};
      }

      this.rn_latitude = data.latitude ? data.latitude : this.rn_latitude;
      this.rn_longitude = data.longitude ? data.longitude : this.rn_longitude;

      if (data.coordinates) {
        this.rn_latitude = data.coordinates.lat;
        this.rn_longitude = data.coordinates.lng;
      }

      this.rn_latitude = this.rn_latitude
        ? parseFloat(this.rn_latitude).toFixed(6)
        : "";
      this.rn_longitude = this.rn_longitude
        ? parseFloat(this.rn_longitude).toFixed(6)
        : "";

      this.rn_above_open = data.heights
        ? parseFloat(data.heights.above_open)
        : this.rn_above_open;
      this.rn_above_roof = data.heights
        ? parseFloat(data.heights.above_roof)
        : this.rn_above_roof;

      this.rn_subscriber = data.subscriber ?? this.rn_subscriber;

      if (data.clicked) {
        this.addressUpdated = false;
        this.findByAddress = false;
        this.remoteChange(false);
        this.getOperators();
      }

      if (data.radius) {
        this.findAddress();
      }
    },
    findAddress(input = false) {
      this.rn_location = {};
      this.latlngUpdated = false;
      if (input) {
        this.rn_latitude = this.rn_latitude
          ? parseFloat(this.rn_latitude).toFixed(6)
          : "";
        this.rn_longitude = this.rn_longitude
          ? parseFloat(this.rn_longitude).toFixed(6)
          : "";
        this.rn_location = {
          lat: Number(this.rn_latitude),
          lng: Number(this.rn_longitude),
        };
        this.latlngUpdated = true;
      }
      if (this.rn_latitude && this.rn_longitude) {
        const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
        const postUrl =
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          this.rn_latitude +
          "," +
          this.rn_longitude +
          "&key=" +
          key;

        // Define the request data
        const requestData = {
          post_url: postUrl,
        };
        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              if (response.data) {
                this.rn_address = response.data.results[0].formatted_address;
                this.addressUpdated = true;
                this.addressError = false;
                this.$emit("get-operators");
              }
            })
            .catch(() => {
              console.log("Error");
            });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },
    getJsonData() {
      let rnObject = {};
      if (this.rn_address != "" && this.rnAddressShow) {
        rnObject.address = this.rn_address;
      }

      if (!this.rnAddressShow) {
        rnObject.latitude =
          this.rn_latitude != "" ? parseFloat(this.rn_latitude) : "";
        rnObject.longitude =
          this.rn_longitude != "" ? parseFloat(this.rn_longitude) : "";
      }

      if (this.rn_subscriber != "") rnObject.subscriber = this.rn_subscriber;

      let heightsObject = {};
      if (this.rn_above_open != "")
        heightsObject.above_open = this.rn_above_open;
      if (this.rn_above_roof != "")
        heightsObject.above_roof = this.rn_above_roof;

      if (Object.keys(heightsObject).length > 0) {
        rnObject.heights = heightsObject;
      }
      return rnObject;
    },
    getOperators() {
      // Basic validation for the address and operator key
      this.latlngUpdated = false;
      if (this.rn_address) {
        this.addressUpdated = true;
        this.addressError = false;
        this.$emit("get-operators"); // Emit event to find operators
      } else {
        this.addressUpdated = false;
        this.addressError = true; // Set error if validation fails
        this.$emit("clear-rn-marker");
      }
    },
  },
};
</script>
